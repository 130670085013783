var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      on: {
        "!keyup": function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.triggerClickOnSearch($event)
        }
      }
    },
    [
      _c("label", { staticClass: "InvoicesSearch__page-label" }, [
        _vm._v("Declined Invoices")
      ]),
      _c(
        "div",
        { staticClass: "column" },
        [
          !_vm.userIsClient
            ? _c(
                "div",
                { staticClass: "InvoicesSearch__search-wrapper" },
                [
                  _c("base-client-search", {
                    staticClass: "InvoicesSearch__client-input",
                    attrs: {
                      "client-i-d-from-query": _vm.apiValues.clientID,
                      label: true,
                      "label-for": "Client"
                    },
                    on: { selected: _vm.setClientID },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "label",
                          fn: function() {
                            return [_vm._v("\n          CLIENT\n        ")]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      3438241474
                    )
                  }),
                  _c("base-debtor-search", {
                    staticClass: "InvoicesSearch__debtor-input",
                    attrs: {
                      "debtor-i-d-from-query": _vm.apiValues.debtorID,
                      label: true,
                      "label-for": "Debtor"
                    },
                    on: { selected: _vm.setDebtorID },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "label",
                          fn: function() {
                            return [_vm._v("\n          DEBTOR\n        ")]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      3347883505
                    )
                  }),
                  _c(
                    "base-button",
                    {
                      staticClass: "InvoicesSearch__buttons bg-white fc-blue",
                      attrs: { "data-cy": "invoice-search-submit-btn" },
                      on: { click: _vm.clickOnSearch }
                    },
                    [
                      _c("i", { staticClass: "fa fa-search-left" }),
                      _vm._v("\n        Search\n      ")
                    ]
                  ),
                  _c(
                    "base-button",
                    {
                      class: [
                        "InvoicesSearch__buttons InvoicesSearch__buttons-filter bg-white fc-blue",
                        { "InvoicesSearch__buttons--outlined": _vm.showFilters }
                      ],
                      attrs: {
                        "aria-label":
                          "Toggles the display of additional search fliter options",
                        "data-cy": "invoice-search-filters-btn",
                        name: "Filters"
                      },
                      on: {
                        click: function($event) {
                          _vm.showFilters = !_vm.showFilters
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fa fa-optionbars" }),
                      _vm._v("\n        Filters\n      ")
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _c("transition", { attrs: { name: "Transition__opacity-fade" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showFilters,
                    expression: "showFilters"
                  }
                ],
                staticClass: "InvoicesSearch__filters-wrapper"
              },
              [
                _c(
                  "base-input",
                  {
                    staticClass: "InvoicesSearch__query-input",
                    attrs: {
                      instructions: _vm.instructions.ID_INSTRUCTION,
                      label: true,
                      "label-for": "InvoiceID",
                      type: "text",
                      valid: _vm.validity.id
                    },
                    model: {
                      value: _vm.inputs.id,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.inputs,
                          "id",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "inputs.id"
                    }
                  },
                  [_vm._v("\n          INVOICE #\n        ")]
                ),
                _c(
                  "base-input",
                  {
                    staticClass: "InvoicesSearch__query-input",
                    attrs: {
                      instructions: _vm.instructions.QUERY_INSTRUCTION,
                      label: true,
                      "label-for": "Query",
                      type: "text",
                      valid: _vm.validity.loadNumber
                    },
                    model: {
                      value: _vm.inputs.loadNumber,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.inputs,
                          "loadNumber",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "inputs.loadNumber"
                    }
                  },
                  [_vm._v("\n          LOAD #\n        ")]
                ),
                _c("v-select", {
                  staticClass: "InvoicesSearch__term-select mt-25",
                  attrs: {
                    "aria-label": "Time As Declined select input",
                    "data-cy": "invoice-search-term-filter",
                    clearable: false,
                    options: _vm.options.terms,
                    placeholder: "Choose your term length"
                  },
                  on: { input: _vm.setTerm },
                  model: {
                    value: _vm.inputs.term,
                    callback: function($$v) {
                      _vm.$set(_vm.inputs, "term", $$v)
                    },
                    expression: "inputs.term"
                  }
                })
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row row--align-center mb-25 mt-25 width-100" },
        [
          _vm.invoices.total > 0
            ? _c(
                "label",
                {
                  staticClass: "InvoicesSearch__search-results-label",
                  attrs: { id: "Testing__TotalSearchResults" }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.invoices.total) +
                      " Total\n      (" +
                      _vm._s(
                        Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD"
                        }).format(_vm.invoices.sum / 100)
                      ) +
                      ")\n    "
                  )
                ]
              )
            : _c(
                "label",
                {
                  staticClass: "InvoicesSearch__search-results-label",
                  attrs: { id: "Testing__TotalSearchResults" }
                },
                [_vm._v("\n      There are no invoices\n    ")]
              ),
          !_vm.userIsClient
            ? _c("base-toggle", {
                staticClass: "InvoicesSearch__expand-toggle",
                attrs: {
                  "active-option": _vm.expandCollapse,
                  "aria-label": "Collapse or expand items",
                  toggles: _vm.options.expandCollapse
                },
                on: { toggle: _vm.emitCollapseStateChange }
              })
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }