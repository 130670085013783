<template>
  <div @keyup.enter.capture="triggerClickOnSearch">
    <label class="InvoicesSearch__page-label">Declined Invoices</label>
    <!-- pending search section (searches only pending): EMPLOYEE view -->
    <div class="column">
      <div
        v-if="!userIsClient"
        class="InvoicesSearch__search-wrapper"
      >
        <!-- Client and Debtor -->
        <base-client-search
          @selected="setClientID"
          class="InvoicesSearch__client-input"
          :client-i-d-from-query="apiValues.clientID"
          :label="true"
          :label-for="'Client'"
        >
          <template v-slot:label>
            CLIENT
          </template>
        </base-client-search>
        <base-debtor-search
          @selected="setDebtorID"
          class="InvoicesSearch__debtor-input"
          :debtor-i-d-from-query="apiValues.debtorID"
          :label="true"
          :label-for="'Debtor'"
        >
          <template v-slot:label>
            DEBTOR
          </template>
        </base-debtor-search>

        <base-button
          @click="clickOnSearch"
          class="InvoicesSearch__buttons bg-white fc-blue"
          data-cy="invoice-search-submit-btn"
        >
          <i class="fa fa-search-left" />
          Search
        </base-button>

        <base-button
          @click="showFilters = !showFilters"
          aria-label="Toggles the display of additional search fliter options"
          :class="[
            'InvoicesSearch__buttons InvoicesSearch__buttons-filter bg-white fc-blue',
            { 'InvoicesSearch__buttons--outlined': showFilters }
          ]"
          data-cy="invoice-search-filters-btn"
          name="Filters"
        >
          <i class="fa fa-optionbars" />
          Filters
        </base-button>
      </div>

      <transition name="Transition__opacity-fade">
        <div
          v-show="showFilters"
          class="InvoicesSearch__filters-wrapper"
        >
          <!-- Invoice # && Load # -->
          <base-input
            v-model.trim="inputs.id"
            class="InvoicesSearch__query-input"
            :instructions="instructions.ID_INSTRUCTION"
            :label="true"
            :label-for="'InvoiceID'"
            :type="'text'"
            :valid="validity.id"
          >
            INVOICE #
          </base-input>
          <base-input
            v-model.trim="inputs.loadNumber"
            class="InvoicesSearch__query-input"
            :instructions="instructions.QUERY_INSTRUCTION"
            :label="true"
            :label-for="'Query'"
            :type="'text'"
            :valid="validity.loadNumber"
          >
            LOAD #
          </base-input>
          <v-select
            v-model="inputs.term"
            @input="setTerm"
            aria-label="Time As Declined select input"
            class="InvoicesSearch__term-select mt-25"
            data-cy="invoice-search-term-filter"
            :clearable="false"
            :options="options.terms"
            :placeholder="'Choose your term length'"
          />
        </div>
      </transition>
    </div>

    <!-- Total invoices and value + Collapse toggles -->
    <div class="row row--align-center mb-25 mt-25 width-100">
      <label
        v-if="invoices.total > 0"
        class="InvoicesSearch__search-results-label"
        id="Testing__TotalSearchResults"
      >
        {{ invoices.total }} Total
        ({{
          Intl
            .NumberFormat('en-US', { style: 'currency', currency: 'USD' })
            .format(invoices.sum / 100)
        }})
      </label>
      <label
        v-else
        class="InvoicesSearch__search-results-label"
        id="Testing__TotalSearchResults"
      >
        There are no invoices
      </label>

      <base-toggle
        v-if="!userIsClient"
        @toggle="emitCollapseStateChange"
        :active-option="expandCollapse"
        aria-label="Collapse or expand items"
        class="InvoicesSearch__expand-toggle"
        :toggles="options.expandCollapse"
      />
    </div>
  </div>
</template>

<script>
// Packages
import moment from 'moment'
// Helpers
import {
  Invoice
} from '../utils/api'
import { debounce, stringifyObjectToQueryString } from '../utils/helpers'
// Components
import BaseButton from './base-button.vue'
import BaseClientSearch from './base-client-search.vue'
import BaseDebtorSearch from './base-debtor-search.vue'
import BaseInput from './base-input.vue'
import BaseToggle from './base-toggle.vue'
// Mixins
import {
  ValidationMixin
} from '../utils/validation-mixin'

export default {
  name: 'DeclinedSearch',

  components: {
    BaseButton,
    BaseClientSearch,
    BaseDebtorSearch,
    BaseInput,
    BaseToggle,
  },

  mixins: [ValidationMixin],

  props: {
    page: {
      type: Number,
      required: true,
    },
    userIsClient: {
      type: Boolean,
      required: true,
    },
  },

  async created () {
    this.$store.commit('CLEAR_INVOICES')

    // declined_date needs initialized since the default value is 'past 6 months'
    this.setTerm('past 6 months')

    // Query string? Compare variables with component data; if different, perform search
    const queryString = window.location.search
    const queryParams = new URLSearchParams(queryString)

    if (queryString) {
      // Client ID
      if (queryParams.has('clientID')) {
        if (queryParams.get('clientID') !== this.apiValues.clientID) {
          this.$set(this.apiValues, 'clientID', queryParams.get('clientID'))
        }
      }

      // Debtor ID
      if (queryParams.has('debtorID')) {
        if (queryParams.get('debtorID') !== this.apiValues.debtorID) {
          this.$set(this.apiValues, 'debtorID', queryParams.get('debtorID'))
        }
      }

      // Invoice ID
      if (queryParams.has('id')) {
        if (queryParams.get('id') !== this.inputs.id) {
          this.$set(this.inputs, 'id', queryParams.get('id'))
        }
      }

      // Load Number
      if (queryParams.has('loadNumber')) {
        if (queryParams.get('loadNumber') !== this.inputs.loadNumber) {
          this.$set(this.inputs, 'loadNumber', queryParams.get('loadNumber'))
        }
      }

      // Term
      if (queryParams.has('term')) {
        if (queryParams.get('term') !== this.inputs.term) {
          this.$set(this.inputs, 'term', queryParams.get('term'))
          this.setTerm(queryParams.get('term'))
        }
      }
    }

    // If userIsClient, set client_id and term
    if (this.userIsClient) {
      const client = JSON.parse(localStorage.getItem('user')).client
      this.$set(this.apiValues, 'clientID', client.id)
      // Overriding term is intended
      this.$set(this.inputs, 'term', 'none')
    }

    this.search()
  },

  mounted () {
    // it needs to refresh results every 5 minutes for clients only
    if (this.userIsClient) {
      this.searchInterval = setInterval(() => {
        this.search()
      }, 5 * 60000)
    }
  },

  beforeDestroy() {
    // it should stop searching when you leave the page
    if (this.searchInterval) clearInterval(this.searchInterval)
  },

  data () {
    return {
      apiValues: {
        clientID: null,
        debtorID: null,
        gt_declined_date: null,
        lt_declined_date: null
      },
      expandCollapse: '<i class="fa fa-4-bars fa-15"></i>',
      inputs: {
        id: '',
        loadNumber: '',
        term: 'past 6 months',
      },
      options: {
        expandCollapse: [
          '<i class="fa fa-4-bars fa-15"></i>',
          '<i class="fa fa-hamburger fa-15"></i>',
        ],
        terms: ['none', 'past 6 months', 'current year', 'last year'],
      },
      searchInterval: null,
      showFilters: false,
      validity: {
        id: true,
        loadNumber: true,
      },
    }
  },

  computed: {
    invoices () {
      return this.$store.getters.invoices
    }
  },

  watch: {
    page () {
      this.search()
    }
  },

  methods: {
    clickOnSearch() {
      // this.CLog('test')
      // this.debounce(
      //   () => {
      this.$emit('reset-pagination')
      this.search()
      //   },
      //   250
      // )
    },

    debounce,

    emitCollapseStateChange (e) {
      this.expandCollapse = e
      this.$emit('collapsed-state-change')
    },

    async search () {
      if (!this.validation()) return

      this.progressStart()

      // Build string for parsing on load (easier than parsing algolia str)
      const urlString = stringifyObjectToQueryString({
        clientID: this.apiValues.clientID,
        debtorID: this.apiValues.debtorID,
        id: this.inputs.id,
        loadNumber: this.inputs.loadNumber,
        term: this.inputs.term,
        offset: this.page,
      })
      // Tell invoices-paid.vue to reset pagination because we're performing a new search
      this.$emit('search')
      // searchParams are null if the user hasn't searched for anything
      // i.e. this covers the case a user just paginates the invoices from onload
      try {
        const results = (await Invoice.queryList({
          client_id: this.apiValues.clientID,
          debtor_id: this.apiValues.debtorID,
          display_id: this.inputs.id.length ? this.inputs.id : null,
          gt___declined_date: this.apiValues.gt_declined_date,
          like___load_number: this.inputs.loadNumber.length ? this.inputs.loadNumber : null,
          lt___declined_date: this.inputs.term === 'last year' ? this.apiValues.lt_declined_date : null,
          offset: this.page - 1,
          sort_by: 'declined_date',
          sort_direction: 'DESC',
          status: 'declined'
        })).data
        // Convert URL to URL representing data of request
        window.history.replaceState({}, '', `?${urlString}`)
        this.$store.commit('STORE_INVOICES', results)
        this.progressFinish()
      }
      catch (error) {
        this.captureSentryEvent(
          'Declined Search "Search" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
            urlString,
          }
        )
        this.CError(error)
        this.requestFailure({
          message: 'There was an issue searching for potential matching invoices',
          title: 'Request error'
        })
      }
    },

    setClientID (filter) {
      if (!filter) {
        this.apiValues.clientID = null
        return
      }
      this.apiValues.clientID = filter.id
    },

    setDebtorID (filter) {
      if (!filter) {
        this.apiValues.debtorID = null
        return
      }
      this.apiValues.debtorID = filter.id
    },

    setTerm (filter) {
      if (filter === 'past 6 months') {
        this.$set(this.apiValues, 'gt_declined_date', moment().subtract(6, 'months').toDate())
        this.$set(this.apiValues, 'lt_declined_date', moment().toDate())
      }
      if (filter === 'current year') {
        this.$set(this.apiValues, 'gt_declined_date', moment().startOf('year').toDate())
        this.$set(this.apiValues, 'lt_declined_date', moment().toDate())
      }
      if (filter === 'last year') {
        this.$set(this.apiValues, 'gt_declined_date', moment().startOf('year').subtract(1, 'years').toDate())
        this.$set(this.apiValues, 'lt_declined_date', moment().startOf('year').toDate())
      }
      if (filter === 'none') {
        this.$set(this.apiValues, 'gt_declined_date', null)
        this.$set(this.apiValues, 'lt_declined_date', null)
      }
    },

    triggerClickOnSearch () {
      // If none of the following elements
      const toMatch = document.activeElement.classList
      if (
        toMatch.contains('InvoicesSearch__buttons-filter')
        || toMatch.contains('vs__search')
      ) {
        return
      }
      this.clickOnSearch()
    },

    validation () {
      let valid = true

      if (this.inputs.id && !this.isValidID(this.inputs.id)) {
        this.$set(this.validity, 'id', false)
        valid = false
      } else {
        this.$set(this.validity, 'id', true)
      }

      if (this.inputs.loadNumber && !this.isValidLoadNumber(this.inputs.loadNumber, true)) {
        this.$set(this.validity, 'loadNumber', false)
        valid = false
      } else {
        this.$set(this.validity, 'loadNumber', true)
      }

      return valid
    },
  },
}
</script>

<style lang="sass">
@import '../styles/invoices-search.sass'
</style>
