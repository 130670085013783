<template>
  <div class="InvoicesDeclined">
    <declined-search
      v-show="!mobile.isMobile"
      class="InvoicesSearch"
      @collapsed-state-change="expandAll = !expandAll"
      @reset-pagination="resetPagination"
      :page="pagination.page"
      :user-is-client="userIsClient"
    />

    <base-invoice-table
      :expand-all="expandAll"
      :status="'declined'"
      :user-is-client="userIsClient"
    />

    <!-- Wait for searchInfo.invoices to have value -->
    <base-pagination
      @paginate="paginate"
      class="InvoicesSearch mt-40"
      :item-count="searchInfo.total"
      :page="pagination.page"
    />
  </div>
</template>

<script>
// Components
import BaseInvoiceTable from '../../components/base-invoice-table.vue'
import BasePagination from '../../components/base-pagination.vue'
import DeclinedSearch from '../../components/declined-search.vue'

export default {
  name: 'DeclinedInvoices',

  components: {
    BaseInvoiceTable,
    BasePagination,
    DeclinedSearch,
  },

  props: {
    userIsClient: {
      type: Boolean,
      required: true,
    },
  },

  created () {
    // Check for offset in query string to set page for paid-search
    const queryString = window.location.search
    const queryParams = new URLSearchParams(queryString)

    if (queryString) {
      if (queryParams.has('offset')) {
        this.$set(this.pagination, 'page', Number(queryParams.get('offset')))
      }
    }
  },

  data () {
    return {
      expandAll: false,
      pagination: {
        page: 1,
        requestLimit: 25,
      },
    }
  },

  computed: {
    mobile () {
      return this.$store.getters.mobile
    },

    searchInfo () {
      const invoices = this.$store.getters.invoices
      return {
        sum: invoices.sum,
        total: invoices.total,
      }
    },
  },

  methods: {
    paginate (page) {
      this.$set(this.pagination, 'page', page)
      window.scrollTo(0, 0)
    },

    resetPagination () {
      this.$set(this.pagination, 'page', 1)
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../styles/invoices-search.sass'
</style>
